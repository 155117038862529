<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Buyers" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
      </div>
      <md-table
        v-model="buyers.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button
              class="md-primary button-icon"
              @click="exportExcel"
              :disabled="exporting"
              :loading="exporting"
            >
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Phone number"
            >{{ item.phone_ext }}{{ item.phone }}</md-table-cell
          >
          <md-table-cell md-label="Fullname">{{
            item.first_name + " " + item.last_name
          }}</md-table-cell>
          <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>

          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <md-menu-item @click="setBuyer(item)">Preview</md-menu-item>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="buyers.mdPage"
        :records="buyers.mdCount"
        :per-page="buyers.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>Buyers Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="buyer">
        <div class="item">
          <strong>First name</strong>
          <span> {{ buyer.first_name }}</span>
        </div>
        <div class="item">
          <strong>Last name</strong>
          <span> {{ buyer.last_name }}</span>
        </div>
        <div class="item">
          <strong>Email Address</strong>
          <span v-html="buyer.email"> </span>
        </div>
        <div class="item">
          <strong>Phone Number</strong>
          <a :href="`tel:${buyer.phone_ext + buyer.phone}`">
            {{ buyer.phone_ext + buyer.phone }}</a
          >
        </div>

        <div class="item">
          <strong>Date Created</strong>
          <span> {{ buyer.created_at | formatDate }}</span>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import axios from "axios";
const { mapActions, mapGetters } = createNamespacedHelpers("user");
export default {
  name: "Buyers",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      show: false,
      time: null,
      buyer: null,
      exporting: false,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getBuyers", "loginAsUser"]),
    async exportExcel() {
      this.exporting = true;
      await this.$store.dispatch("auth/export", { model: "buyers-users" });
      this.exporting = false;
    },
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getBuyers(data);
    },
    async submitSearch() {
      await this.getBuyers({ keyword: this.search });
    },

    setBuyer(buyer) {
      this.show = true;
      this.buyer = buyer;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async loginAs(id, name) {
      const c = confirm(`Are you sure you want to login as ${name}?`);

      if (c) {
        const { token, redirect_url } = await this.loginAsUser(id);
        window.open(redirect_url, "_blank");
      }
    },
    async validate() {
      await axios.post("http://127.0.0.1:8000/api/admin/auth/validate", {
        code: "2652|ldUWo3fGaIvWobEcBBK2qItY7qXR5NNJb9bhybVw",
      });
    },
  },
  created() {},
  mounted() {
    this.getBuyers();
  },
  computed: {
    ...mapGetters(["buyers", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}
</style>
